import * as React from 'react';
import Button from "react-bootstrap/Button";
import {ContainerPanel} from "../components/container-panel";
import quin1 from '../assets/images/quin1.jpg';
import quin2 from '../assets/images/quin2.jpg';
import quin3 from '../assets/images/quin3.jpg';
import quin4 from '../assets/images/quin4.jpg';
import quin5 from '../assets/images/quin5.jpg';
import {useHistory} from "react-router";

const styles = {
    spacing: {
        margin: 4
    }
}

export function QuinScreen() {

    const history = useHistory();

	const navigate = (route, data) => {
		if(data) history.push({pathname: route, state: data});
		else history.push(route);
	}

    return (
        <ContainerPanel id="quin">
            <div className="row" style={{marginTop: 24}}>
                <div className="col-md-6"  style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: 64}}>
                    <h1 className="title-text" style={{color: 'black'}}>The 'Quin House</h1>
                    <h3 className="subtitle-text">Optimal Fitness has partnered with the hottest new social club in Boston - THE 'QUIN HOUSE. The ‘Quin House is a gathering place that reflects the new Boston—a city that continues to transform itself into a wellspring of technological, artistic, and cultural innovation. Nestled in the heart of Boston’s Back Bay neighborhood at 217 Commonwealth Avenue, this diverse social club will bring together the region’s leaders, creators, innovators, and rising stars to forge meaningful connections. The clubhouse was originally The Algonquin Club, established in 1886. It remains the only building in Boston specifically designed as a social club. The ‘Quin House's interior has been re-imagined by renowned Creative Director and Designer, Ken Fulk as a vibrant playground for the mind, body and spirit. It is designed to stimulate the senses from eye-catching decor to the delicious culinary experiences and exciting programming. The result is a chic, comfortable and inviting home away from home. </h3>
                    <br/><h3 className="subtitle-text">Optimal Fitness is excited to join Hexagon Properties in creating a place for members to connect, dine, play, inspire, and train. The Health Club at The ‘Quin House is a destination for strength training, fitness classes and rejuvenation. We are fully equipped with a cardio room, weight room and a studio that offers daily classes. In addition to our specialized training facilities the Health Club offers daily massage and facials to members in a relaxing treatment room. </h3>
                    <br/><i><h3 className="subtitle-text">We are hiring part-time concierge and gym coverage staff. </h3></i>
					<Button variant="dark" style={styles.spacing} onClick={() => navigate('/contact', {loc: 'The \'Quin House'})}>Contact Us</Button>
                </div>
                <div className="col-md-6" style={{marginBottom: 8}}>
                    <div style={{width: '100%', textAlign: 'center'}} className="card">
                        <img src={quin5} style={{width: '100%', height: 'auto', objectFit: 'cover'}}/>
                    </div>
                    <div style={{width: '100%', textAlign: 'center'}} className="card">
                        <img src={quin4} style={{width: '100%', height: 'auto', objectFit: 'cover'}}/>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', textAlign: 'center'}} className="card">
                <img src={quin3} style={{width: '100%', height: 'auto', objectFit: 'cover'}}/>
            </div>
        </ContainerPanel>
    );
};
