import * as React from 'react';
import {useState} from "react";
import {Navbar} from 'react-bootstrap';
import {XCircle} from "react-bootstrap-icons";
const styles = {
    grid: {
        padding: 16,
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto'
    },
    link: {
        color: 'dodgerblue',
        cursor: 'pointer'
    }
}

export function Snackbar() {

    const [show, setShow] = useState(true);

    const close = () => {
        setShow(false);
    }

    if(!show) return null;

    return (
        <Navbar expand="lg" bg="light" variant="light" fixed="bottom" expanded={false} style={{borderTop: '1px solid black'}}>
            <div style={styles.grid}>
                <div>
                    <h3>All emails will be answered starting July 10th after the Independence Day break!</h3>
                </div>
                <XCircle className="header-icon" color='black' size={24} style={{marginLeft: 8}} onClick={close}/>
            </div>
        </Navbar>
    );
};
